window.productCardsFunc = function productCardsFunc() {
  let goods = document.querySelectorAll('.goods-card');
  let orderControls = document.querySelectorAll('.product-order-controls');
  if (goods) {
    goods.forEach((card) => {
      if (!card.classList.contains('is-init')) {
        card.classList.add('is-init');

        let cardOrderBasket = card.querySelector('.goods-card-order-basket');
        let cardOrderIncrement = card.querySelector('.plus');
        let cardOrderDecrement = card.querySelector('.minus');
        let cardOrderTotal = card.querySelector('.goods-card-qty');
        let cardSave = card.querySelector('.goods-card-save');

        if (cardSave) {
          cardSave.addEventListener('click', function (event) {
            event.preventDefault();
            this.classList.toggle('active');
          });
        }

        cardOrderBasket.addEventListener('click', function () {
          card.classList.add('active');
          cardOrderTotal.value = 1;
        });

        cardOrderIncrement.addEventListener('click', function () {
          if (parseFloat(cardOrderTotal.textContent) < 999) {
            cardOrderTotal.textContent =
              parseFloat(cardOrderTotal.textContent) + 1;
          }
          if (parseFloat(cardOrderTotal.textContent) >= 999) {
            cardOrderIncrement.classList.add('disabled');
          }
        });

        cardOrderDecrement.addEventListener('click', function () {
          cardOrderTotal.textContent =
            parseFloat(cardOrderTotal.textContent) - 1;
          cardOrderIncrement.classList.remove('disabled');
          if (parseFloat(cardOrderTotal.textContent) === 0) {
            card.classList.remove('active');
          }
        });

        const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach((mutation) => {
            if (
              mutation.type === 'attributes' &&
              mutation.attributeName === 'value'
            ) {
              console.log(cardOrderTotal.value);
              if (cardOrderTotal.value === '0') {
                card.classList.remove('active');
              }
            }
          });
        });

        observer.observe(cardOrderTotal, { attributes: true });

        cardOrderTotal.addEventListener('change', (e) => {
          console.log(cardOrderTotal.value);
          if (cardOrderTotal.value === '0') {
            card.classList.remove('active');
          }
        });
      }
    });
  }

  if (orderControls) {
    orderControls.forEach((control) => {
      if (!control.classList.contains('is-init')) {
        control.classList.add('is-init');

        console.log(control);

        let cardOrderIncrement = control.querySelector('.plus');
        let cardOrderDecrement = control.querySelector('.minus');
        let cardOrderTotal = control.querySelector('.product-order-qty');

        control.addEventListener('click', function (e) {
          e.preventDefault();
        });

        cardOrderIncrement.addEventListener('click', function () {
          cardOrderTotal.textContent =
            parseFloat(cardOrderTotal.textContent) + 1;
          cardOrderDecrement.classList.remove('disabled');
        });

        cardOrderDecrement.addEventListener('click', function () {
          if (parseFloat(cardOrderTotal.textContent) > 0) {
            cardOrderTotal.textContent =
              parseFloat(cardOrderTotal.textContent) - 1;
          }
          if (parseFloat(cardOrderTotal.textContent) < 1)
            cardOrderDecrement.classList.add('disabled');
        });

        const observer = new MutationObserver((mutationsList) => {
          mutationsList.forEach((mutation) => {
            if (
              mutation.type === 'attributes' &&
              mutation.attributeName === 'value'
            ) {
              console.log(cardOrderTotal.value);
              if (cardOrderTotal.value === '0') {
                cardOrderDecrement.classList.add('disabled');
              }
            }
          });
        });

        observer.observe(cardOrderTotal, { attributes: true });

        cardOrderTotal.addEventListener('change', (e) => {
          console.log(cardOrderTotal.value);
          if (cardOrderTotal.value === '0') {
            cardOrderDecrement.classList.add('disabled');
          }
        });
      }
    });
  }
};

productCardsFunc();

$('body').on('click', '.remove-btn', function (e) {
  e.preventDefault();
  console.log('e.prevented');
});

window.reloadCardsTotalCount = function reloadCardsTotalCount() {
  const cardsOrderAll = document.querySelectorAll('.goods-card');
  cardsOrderAll.forEach((card) => {
    let cardOrderTotal = card.querySelector('.goods-card-qty');
    if (cardOrderTotal.value === '0') {
      card.classList.remove('active');
    }
  });
};
