import Swiper, { Autoplay, Navigation, Pagination, EffectFade } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let footerSwiperWr = document.querySelectorAll('.footer-swiper-wr');
footerSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let pagination = el.querySelector('.swiper-pagination');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Pagination, EffectFade],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      speed: 500,
      threshold: 10,
      allowTouchMove: false,
      effect: 'fade',
      autoHeight: true,
      pagination: {
        el: pagination,
        type: 'bullets',
        clickable: true,
      },
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
    });
  }
});
