
window.openCartPopup = function openCartPopup(){
    let cartPopup = document.querySelector('.cart-popup');
    if(cartPopup){
        $(cartPopup).fadeIn({
            start: function(){
                $(this).css('display', 'flex');
                scrollLock.disablePageScroll(this)
                scrollLock.addScrollableSelector(this)
            },
            complete: function(){
                $(this).addClass('active');
            }
        });
    }
}
window.closeCartPopup = function closeCartPopup(){
    let cartPopup = document.querySelector('.cart-popup');
    if(cartPopup){
        $(cartPopup).fadeOut({
            start: function(){
                $(this).removeClass('active');
                scrollLock.enablePageScroll();
            }
        });
    }
}