import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let productInfoSwiperWr = document.querySelectorAll('.product-info-swiper-wr');
productInfoSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectCreative],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      speed: 800,
      threshold: 10,
      allowTouchMove: false,
      autoHeight: true,
      effect: 'creative',
      creativeEffect:{
        prev:{
          translate: [-20, 0, 0],
          scale: 0.95
        },
        next:{
          translate: [20, 0, 0],
          scale: 0.95
        }
      }
    });

    let swiperControls = document.querySelectorAll('.product-info-tabs-item');
    if(swiperControls){
      swiperControls.forEach((item, idx)=>{
        item.addEventListener('click', function(){
          swiper.slideTo(idx);
          swiperControls.forEach((el, i)=> idx === i ? el.classList.add('active') : el.classList.remove('active'))
        })
      })
    }
  }
});


