let orderItems = $('.confirm-item-header i');
if (orderItems.length) {
  orderItems.each((idx, el) => {
    $(el).click(function () {
      $(this).parent().toggleClass('active');
      $(this).parent().next().stop(true, true).slideToggle();
    });
  });
}

const orderComponent = document.querySelector('.hero-order');
if (orderComponent) {
  const novaRadio = orderComponent.querySelector('input[value="nova"]');
  const pickupRadio = orderComponent.querySelector('input[value="pickup"]');
  const cashItem = orderComponent.querySelector('.confirm-item-cash');

  if (novaRadio.checked) {
    cashItem.classList.add('hidden');
  } else {
    cashItem.classList.remove('hidden');
  }

  pickupRadio.addEventListener('change', function () {
    if (pickupRadio.checked) {
      cashItem.classList.remove('hidden');
    } else {
      cashItem.classList.add('hidden');
    }
  });

  novaRadio.addEventListener('change', function () {
    if (novaRadio.checked) {
      cashItem.classList.add('hidden');
    } else {
      cashItem.classList.remove('hidden');
    }
  });
}
