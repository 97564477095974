import './utils/vh.utils.js';
import './utils/animate-on-view.utils';

import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/meta-select-component.js';
import './components/header.component.js';
import './components/cards-orders.component.js';
import './components/shop-cart.component.js';
import './components/order-page.component.js';

/*swipers*/
import './components/swiper-hero-banner.component.js';
import './components/swiper-blogs.component.js';
import './components/swiper-hero-product.component.js';
import './components/swiper-product-info.component.js';
import './components/swiper-goods.component.js';
import './components/swiper-locations.component.js';
import './components/swiper-article.component.js';
import './components/swiper-team.component.js';
import './components/swiper-footer.component.js';
import './components/swiper-hero-about.component.js';

import './libs/countUp.lib.js';

let userAgent = navigator.userAgent.toLowerCase();

let montereyOS =
  /version\/15.6/gi.test(userAgent) && /mac os/gi.test(userAgent);
let iphone = /iphone/.test(navigator.userAgent.toLowerCase());
let ipad = /ipad/.test(navigator.userAgent.toLowerCase());

if (montereyOS) {
  document.querySelector('body').classList.add('safari-15-support');
}
if (iphone) {
  document.querySelector('body').classList.add('iphone-support');
}
if (ipad) {
  document.querySelector('body').classList.add('ipad-support');
}

let insightsFiltersToggle = $('.insights-form-categories h3');
if (insightsFiltersToggle.length) {
  insightsFiltersToggle.click(function () {
    $(this).toggleClass('active');
    $(this)
      .next('.insights-form-categories-list')
      .stop(true, true)
      .slideToggle();
  });
}

const footerNavbarMenu = document.querySelector('.footer-navbar-menu.category');
if (footerNavbarMenu) {
  const list = footerNavbarMenu.querySelector('ul');
  const items = list.querySelectorAll('ul li');

  if (items.length < 4) {
    footerNavbarMenu.classList.add('one-column');
  } else if (items.length < 7) {
    footerNavbarMenu.classList.add('two-column');
  }
}
