import Swiper, {Autoplay, Navigation, EffectFade} from 'swiper';

import { swiperObserver } from '../utils/swiper.utils.js';

let locationsSwiperWr = document.querySelectorAll('.locations-swiper-wr');
locationsSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, EffectFade],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      speed: 800,
      threshold: 10,
      allowTouchMove: false,

      effect: 'fade',
      fadeEffect:{
        crossFade: true
      }
    });

    let swiperControls = document.querySelectorAll('.hero-locations-tab');
    if(swiperControls){
      swiperControls.forEach((item, idx)=>{
        item.addEventListener('click', function(){
          swiper.slideTo(idx);
          swiperControls.forEach((el, i)=> idx === i ? el.classList.add('active') : el.classList.remove('active'))

          let scrollTop = swiperEl.offsetTop;
          window.scroll({
            top: scrollTop - 100,
            behavior: "smooth"
          })
        })
      })
    }
  }
});


